<template>
  <div :class="['tw-flex tw-flex-row', oldStyle ? 'tw-gap-8' : 'tw-gap-4']">
    <datePicker
      v-model="startDate"
      :config="config"
      :class="{ 'formulate-text': !oldStyle }"
  />
    <datePicker
      v-model="endDate"
      :config="config"
      :class="{ 'formulate-text': !oldStyle }"
    />
  </div>
</template>

<script>
import moment from 'moment'
import datePicker from 'vue-bootstrap-datetimepicker'
import { parse } from 'date-fns'

export default {
  name: 'DateRangePicker',
  components: {
    datePicker
  },
  props: {
    oldStyle: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      startDate: new Date(),
      endDate: '',
      config: {
        format: 'DD-MM-YYYY HH:mm',
        locale: 'nl-be'
      }
    }
  },
  watch: {
    startDate (startDate) {
      if (startDate) this.validateAndCorrectEndDate()

      this.$emit('dateUpdated', {
        startDate,
        endDate: this.endDate,
        source: 'dateRangePicker'
      })
    },
    endDate () {
      this.$emit('dateUpdated', {
        startDate: this.startDate,
        endDate: this.endDate,
        source: 'dateRangePicker'
      })
    }
  },
  methods: {
    setStartDate (date) {
      this.startDate = moment(date, 'DD-MM-YYYY HH:mm').format('DD-MM-YYYY HH:mm')
    },
    setEndDate (date) {
      this.endDate = moment(date, 'DD-MM-YYYY HH:mm').format('DD-MM-YYYY HH:mm')
    },
    getStartDateAsDatetime () {
      return parse(this.startDate, 'dd-MM-yyyy HH:mm', new Date())
    },
    getEndDateAsDatetime () {
      return parse(this.endDate, 'dd-MM-yyyy HH:mm', new Date())
    },
    getStartDate () {
      if (this.startDate) {
        return moment(this.startDate, 'DD-MM-YYYY HH:mm').format('YYYY-MM-DD HH:mm')
      } else {
        return null
      }
    },
    getEndDate () {
      if (this.endDate && this.endDate !== 'Invalid date') {
        return moment(this.endDate, 'DD-MM-YYYY HH:mm').format('YYYY-MM-DD HH:mm')
      } else {
        return null
      }
    },
    /* Checks whether a startDate and or an endDate are entered. */
    datesEntered (startDate, endDate) {
      const exists = []
      if (startDate) exists.push(this.startDate)
      if (endDate) exists.push(this.endDate)
      if (exists.indexOf('Invalid date') > -1 || exists.indexOf('') > -1) {
        return false
      } else {
        return true
      }
    },
    validateAndCorrectEndDate () {
      // If the user changes the start date such that the difference between end and start date is less than 1 hour
      // This function corrects that automatically and sets the endDate to startDate + 1 hour.
      const start = this.getStartDate()
      const end = this.getEndDate()

      const minuteDifference = moment(end).diff(start, 'minutes')
      if (minuteDifference < 30) {
        this.endDate = moment(start).add(30, 'minutes')
      }
    }
  }
}
</script>

<style src="../../../node_modules/eonasdan-bootstrap-datetimepicker/build/css/bootstrap-datetimepicker.css"></style>
